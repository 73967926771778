import React from "react";
import { HashRouter, Link, Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ErrorPage from "../pages/ErrorPage";
import Feature from "../pages/Feature";
import Home from "../pages/Home";
import Inscription from "../pages/Inscription";
import Pricing from "../pages/Pricing";
const NavBar = () => {
  return (
    <HashRouter >
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            Navbar
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link active" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/feature">
                  Features
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/pricing">
                  Pricing
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/inscription">
                  Inscription
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/feature" element={<Feature />} />
        <Route exact path="/pricing" element={<Pricing />} />
        <Route path="/inscription" element={<Inscription />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>

    </HashRouter>
  );
};

export default NavBar;
// classNameName NavBar extends Component {
//   render() {
//     return (
//       <nav classNameName="navbar navbar-light bg-light">
//         <div classNameName="container-fluid">
//           <a classNameName="navbar-brand" href="#">
//             Navbar{" "}
//             <span classNameNameName="badge rounded-pill bg-secondary">
//               {this.props.totalCounters}
//             </span>
//           </a>
//         </div>
//       </nav>
//     );
//   }
// }

// export default NavBar;
