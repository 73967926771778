import React from 'react';
import Lalana from "../components/lalana";

const Feature = () => {
  return (
    <>
      <Lalana />
    </>
  );
}

export default Feature;
