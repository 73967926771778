import React, { Component } from "react";

import Form from "../components/form";
class Inscription extends Component {
  render() {
    return <Form />;
  }
}

export default Inscription;
