import React from "react";

const Input = (props) => {
  const { label, onChange, id, ...inputProps } = props;
  return (
    <React.Fragment>
      <div className="mb-3">
        <label className="form-label">{label}</label>
        <input {...inputProps} onChange={onChange} className="form-control" />
      </div>
    </React.Fragment>
  );
};

export default Input;
