import React from "react";
// import Student from "../components/student";
// class Home extends Component {
//   state = {};
//   render() {
//     return (
//       <React.Fragment>
//         <h1>This is my home page</h1>;
//         <Student />
//       </React.Fragment>
//     );
//   }
// }

import Lalana from "../components/lalana";

// export default Home;
const Home = () => {
  return (
    <>
      <h1>Home Manova</h1>
      <Lalana />
    </>
  );
}

export default Home;