import React, { useEffect, useState } from 'react';

const Lalana = () => {
    const [type, setType] = useState([]);
    const url = "http://192.168.104.43:8080/lalana/type";
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                setType(data);
            })
            .catch((err) => {
                console.log(err.message);
            });
    };
    return (
        <>
            <ul>
                {
                    type.map((t, i) => (
                        <li id={i}> {t.nom}</li>
                    ))
                }
            </ul>
        </>
    );
}

export default Lalana;